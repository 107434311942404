import * as React from "react"
import { experienceList, educationList, courseList, toolList, personalInfo } from "../data/resume"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin, faSafari } from "@fortawesome/free-brands-svg-icons"

const SectionTitle = ({ title }) => {
  return (
    <>
      <h2 className="text-2xl mb-5 uppercase">{title}</h2>
    </>
  )
}

const ResumeSection = ({ title, children, hideTitle, className }) => {
  const titleHidden = hideTitle || false
  return (
    <>
      <div className={className}>
        {!titleHidden && <SectionTitle title={title} />}
        {children}
      </div>
    </>
  )
}

const Experience = ({ experience }) => {
  const showDescription = experience.description && experience.description.trim() !== ""
  const showHighlights = experience.highlights && experience.highlights.length > 0
  return (
    <>
      <div>
        <span className="text-sm text-gray-500">{experience.start} • {experience.end}</span>
        <h3 className="font-serif text-lg font-medium -mb-1">{experience.company}</h3>
        <h4 className="font-serif text-lg font-medium text-gray-500">{experience.title}</h4>
        {showDescription && <p className="mt-1 text-gray-500">{experience.description}</p>}
        {showHighlights && 
          <ul className="pl-5 list-disc text-gray-500">
            {experience.highlights.map((el, i) => {
              return <li key={i}>{el}</li>
            })}
          </ul>
        }
      </div>
    </>
  )
}

const Education = ({ education }) => {
  return (
    <>
      <div className="flex flex-col">
      <span className="text-sm text-gray-500">
        {education.start === education.end
        ? education.start
        : education.start + " - " + education.end}
        {education.location
        ? ` (${education.location})`
        : ""}
      </span>
        <h3 className="font-serif text-lg font-medium -mb-1">{education.certification}</h3>
        <h4 className="font-serif text-lg font-medium text-gray-500">{education.school}</h4>
      </div>
    </>
  )
}

// Sections
const SummarySection = ({ className }) => {
  return (
    <>
      <ResumeSection title="Resumo" className={className}>
        <p>{personalInfo.summary}</p>
      </ResumeSection>
    </>
  )
}

const EducationSection = ({ className }) => {
  return (
    <>
      <ResumeSection title="Formação Acadêmica" className={className}>
        <div className="space-y-7">
          {educationList.map((education, i) => {
            return <Education education={education} key={i}/>
          })}
        </div>
      </ResumeSection>
    </>
  )
}

const CourseSection = ({ prefix, postfix, className }) => {
  const l = courseList.length
  const couses = prefix && prefix > 0
    ? courseList.slice(0, prefix)
    : postfix && postfix > 0 
      ? courseList.slice(l-postfix, l)
      : courseList

  const hideTitle = postfix > 0

  return (
    <>
      <ResumeSection title="Cursos" hideTitle={hideTitle} className={className}>
        <div className="space-y-7">
          {couses.map((education, i) => {
            return <Education education={education} key={i} />
          })}
        </div>
      </ResumeSection>
    </>
  )
}

const ToolsSection = ({ className }) => {
  return (
    <>
      <ResumeSection title="Ferramentas" className={className}>
        <ul className="flex flex-row flex-wrap">
          {toolList.map((tool, i) => {
            return <li className="rounded-full bg-gray-100 px-3 mr-2 mb-2" key={i}>{tool}</li>
          })}
        </ul>
      </ResumeSection>
    </>
  )
}

const LogoSection = ({ className }) => {
  const links = [
    {show: personalInfo.linkedin, icon: faLinkedin, text: `/${personalInfo.linkedin}`, href: `https://www.linkedin.com/in/${personalInfo.linkedin}/`},
    {show: personalInfo.website, icon: faSafari, text: `${personalInfo.website}`, href: personalInfo.website},
  ]

  const age = personalInfo.age && `${personalInfo.age} anos`

  const subtitle = [
    age,
    personalInfo.location,
  ].filter((e) => e && e.length > 0)
  .join(" • ")

  return (
    <>
      <div className={`flex flex-col ${className}`}>
        <div className="flex flex-col">
          <span className="font-thin text-5xl sm:text-6xl md:text-8xl">
            {personalInfo.firstName}
          </span>
          <span className="font-bold text-5xl sm:text-6xl md:text-8xl md:-mt-4">{personalInfo.lastName}</span>
        </div>
        <p className="flex flex-row mt-4">
          {subtitle}
          <br/>
          {personalInfo.email}
          <br />
          {personalInfo.mobile}
        </p>  
        <ul className="flex flex-col md:flex-row md:space-x-6 mt-4">
          {links.map(({show, icon, text, href}, i) => {
            return show ? <li key={i}><a href={href}><FontAwesomeIcon icon={icon} /> <span className="text-gray-500">{text}</span></a></li> : ""
          })}
        </ul>
      </div>
    </>
  )
}

const ExperienceSection = ({ from, to, className }) => {
  const experiences = experienceList.slice(from, to)
  const hideTitle = from > 0
  return (
    <>
      <ResumeSection title="Experiência" hideTitle={hideTitle} className={className}>
        <div className="space-y-7">
          {experiences.map((experience, i) => {
            return <Experience experience={experience} key={i}/>
          })}
        </div>
      </ResumeSection>
    </>
  )
}

export { SummarySection, EducationSection, CourseSection, ExperienceSection, LogoSection, ToolsSection}
