import * as React from "react"
import { SummarySection, EducationSection, CourseSection, ExperienceSection, LogoSection, ToolsSection} from "../../components/resume"

const ResumePage = () => {
  return (
    <>
      <main className="px-12 py-24 container mx-auto flex flex-col md:px-24">
        <title>Currículo | Beatriz Arantes</title>
        <div className="grid z-10 grid-cols-1 gap-16 md:grid-cols-5">
          <LogoSection className="md:col-span-3 md:row-start-1 md:row-span-2 md:col-start-3" />
          <SummarySection className="md:col-span-2 md:row-start-1 md:col-start-1 md:mt-8" />
          <EducationSection className="md:col-span-2 md:row-start-2 md:col-start-1" />
          <ExperienceSection className="md:col-span-3 md:row-start-3 md:col-start-3 md:row-span-5 md:-mt-32" />
          <CourseSection className="md:col-span-2 md:row-start-3 md:col-start-1" />
          <ToolsSection className="md:col-span-2 md:row-start-4 md:col-start-1" />
          <div className="-z-1 invisible md:visible md:row-start-1 md:col-start-3 col-span-0">
            <div className="fixed h-screen w-screen bg-gray-100 top-0 -ml-10"></div>
          </div>
        </div>
      </main>
    </>
  )
}

export default ResumePage
